// Firebase V9
import { initializeApp } from 'firebase/app'
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll
} from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC6oNpmVvSdPnEWevEVMQ-ljkXyQKFw46s',
  authDomain: 'insumosmedicos-52a12.firebaseapp.com',
  projectId: 'insumosmedicos-52a12',
  storageBucket: 'insumosmedicos-52a12.appspot.com',
  messagingSenderId: '723243280441',
  appId: '1:723243280441:web:a08c5e4a903855422645e8'
}
// Initialize Firebase
const fapp = initializeApp(firebaseConfig)
const storage = getStorage(fapp)

const urlAvanzado = 'CuidadoAvanzado/'
const urlTradicional = 'CuidadoTradicional/'
const urlVentilatorio = 'CuidadoVentilatorio/'
const urlLogo = 'logos.com/'
const referenciaFirebase = ref
export {
  fapp,
  storage,
  urlAvanzado,
  urlTradicional,
  urlVentilatorio,
  urlLogo,
  referenciaFirebase,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll
}
