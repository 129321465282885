<template>
  <NavegacionComponents />
  <LinksSociales />
  <div class="nosotrosComponents">
    <p class="nosotros">
      <img src="@/assets/img/comillas.png" /> NALIMED es una empresa
      especializada en insumos, servicios y tecnología médica, que tiene como
      objetivo proporcionar soluciones médicas de una forma eficaz y cercana.
      Contamos con profesionales especialistas en diversas áreas de la salud
      humana. Queremos darnos a conocer por nuestros precios, calidad de
      producto, contamos con personal capacitado, cubriendo las necesidades de
      forma más eficaz y eficiente garantizando su satisfacción .
      <br />
      Brindamos soluciones desde el año 2021, sumando cada día experiencias
      exitosas de apoyo a nuestros clientes particulares e institucionales.
      <br />
      <br />
      Estamos trabajando para convertirnos en su socio a largo plazo.
      <br />
      Gracias
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import NavegacionComponents from '@/components/navegacion/navegacionComponents.vue'
import LinksSociales from '@/components/LinksSociales/linksSociales.vue'

export default defineComponent({
  setup() {
    return {}
  },
  components: {
    NavegacionComponents,
    LinksSociales
  }
})
</script>
