import { createRouter, createWebHistory } from 'vue-router'
import HomeComponents from '@/views/homeComponents.vue'
import tiendaComponents from '@/views/tiendaComponents.vue'
import loginComponents from '@/views/loginComponents.vue'
import panelAdministrativo from '@/views/panelAdministrativo.vue'
import presentacionComponents from '@/views/presentacionComponents.vue'
import confirmationComponent from '@/views/pagoExitoso.vue'

const routes = [
  { path: '/', name: 'Home', component: HomeComponents },
  { path: '/tienda', name: 'tienda', component: tiendaComponents },
  { path: '/login', name: 'login', component: loginComponents },
  {
    path: '/panelA',
    name: 'panelA',
    component: panelAdministrativo,
    meta: { requiresAuth: true }
  },
  {
    path: '/pagoexitoso',
    name: 'pagoExistoso',
    component: confirmationComponent
  },
  {
    path: '/presentacion',
    name: 'presentacionComponents',
    component: presentacionComponents
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth && !localStorage.getItem('token')) {
    next({ path: '/login' })
  } else if (!requiresAuth && localStorage.getItem('token')) {
    next()
  } else {
    next()
  }
})

export default router
