import productos from '@/apicall/productos.js'
export default {
  state: {
    productos: [],
    error: null
  },
  getters: {
    Getproductos: (state) => state.productos,
    Geterror: (state) => state.error
  },
  mutations: {
    EditProductos: (state, productos) => {
      state.productos = productos
    },
    EditError: (state, error) => {
      state.error = error
    }
  },
  actions: {
    async llamadaProductos({ commit }, url) {
      try {
        const jwt = localStorage.getItem('token')
        const response = await productos.Getproductos(url, jwt)
        commit('EditProductos', response.data)
      } catch (error) {
        commit('EditError', error)
      }
    }
  }
}
