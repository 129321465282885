<template>
  <div>
    <p class="tituloAdm">Panel Admninistrativo</p>
    <div class="">
      <p class="bg-danger text-white">
        Panel Administrativo para uso exclusivo en computadoras
      </p>
    </div>
    <div class="panelAdministrativo">
      <div class="navegacionPanel">
        <ul>
          <li>
            <a @click="enlaces('panelLogoAdm')">Logo-Portada</a>
          </li>
          <li>
            <ul class="menu-categoria">
              <span @click="mostrarSubMenu('menuCategoria')">Categorias</span>
              <div
                class="subMenu-categorias"
                :class="{ show: submenu == 'menuCategoria' }"
              >
                <li @click="enlaces('cuidadoAvanzado')">{{ categoria3 }}</li>
                <li @click="enlaces('tradicional')">{{ categoria2 }}</li>
                <li @click="enlaces('terapia')">{{ categoria1 }}</li>
              </div>
            </ul>
          </li>
          <li>
            <a @click="enlaces('linksRedesAdm')">Link Redes</a>
          </li>
          <li>
            <a @click="enlaces('destacados')">Destacados</a>
          </li>
          <li>
            <button class="btn btn-danger" @click="cerrarSesion">LogOut</button>
          </li>
        </ul>
      </div>
      <div class="panelesAdministrativos">
        <categoriasAdm v-if="enlaceAdm == 'categoriasAdm'" />
        <linksRedesAdm v-if="enlaceAdm == 'linksRedesAdm'" />
        <panelLogoAdm v-if="enlaceAdm == 'panelLogoAdm'" />
        <destacadosAdm v-if="enlaceAdm == 'destacados'" />
        <subMenuCuidadoAvanzado
          :categoria="categoria3"
          @refresh="obtenerValoresSubPanels"
          v-if="enlaceAdm == 'cuidadoAvanzado'"
        />
        <subMenuCuidadoTradicional
          :categoria="categoria2"
          @refresh="obtenerValoresSubPanels"
          v-if="enlaceAdm == 'tradicional'"
        />
        <subMenuCuidadoTerapia
          :categoria="categoria1"
          @refresh="obtenerValoresSubPanels"
          v-if="enlaceAdm == 'terapia'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, toRefs } from 'vue'
import categoriasAdm from '@/components/panelAdm/subPaneles/subPanelCuidadoAvanzado.vue'
import linksRedesAdm from '@/components/panelAdm/linkRedesSocialesAdm.vue'
import panelLogoAdm from '@/components/panelAdm/panelLogo.vue'
import destacadosAdm from '@/components/panelAdm/destacadosAdm.vue'
import subMenuCuidadoAvanzado from '@/components/panelAdm/subPaneles/subPanelCuidadoAvanzado.vue'
import subMenuCuidadoTradicional from '@/components/panelAdm/subPaneles/subPanelCuidadoTradicional.vue'
import subMenuCuidadoTerapia from '@/components/panelAdm/subPaneles/subPanelTerapiaVentilatoria.vue'
import AutoAdmin from '@/apicall/autoAdmin'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const enlaceAdm = ref('productos')
    const submenu = ref()
    const router = useRouter()
    const categorias = reactive({
      categoria1: '',
      categoria2: '',
      categoria3: ''
    })
    const obtenerValoresSubPanels = async () => {
      const response = await AutoAdmin.getCategorias()
      categorias.categoria1 = response.data[0].nombre
      categorias.categoria2 = response.data[1].nombre
      categorias.categoria3 = response.data[2].nombre
    }
    const enlaces = (Nombre) => {
      enlaceAdm.value = Nombre
    }
    const mostrarSubMenu = (menu) => {
      submenu.value = submenu.value == menu ? '' : menu
    }
    const cerrarSesion = async () => {
      localStorage.removeItem('token')
      router.push('/login')
    }
    onMounted(() => {
      obtenerValoresSubPanels()
    })
    return {
      enlaces,
      enlaceAdm,
      categorias,
      mostrarSubMenu,
      submenu,
      ...toRefs(categorias),
      obtenerValoresSubPanels,
      cerrarSesion
    }
  },
  components: {
    categoriasAdm,
    linksRedesAdm,
    panelLogoAdm,
    subMenuCuidadoAvanzado,
    subMenuCuidadoTradicional,
    subMenuCuidadoTerapia,
    destacadosAdm
  }
})
</script>
