<template>
  <div class="contenidoNavegacion" id="contenidoNavegacion">
    <div ref="scrollNav" class="navLogo" id="navLogo">
      <div @click="botonHamburguesa" id="menuHamburguesa">
        <div
          :class="{ rotar1: banderaNav }"
          class="hamburguesaHover"
          id="linea1"
        ></div>
        <div
          :class="{ rotar2: banderaNav }"
          class="hamburguesaHover"
          id="linea2"
        ></div>
        <div
          :class="{ rotar3: banderaNav }"
          class="hamburguesaHover"
          id="linea3"
        ></div>
      </div>
      <div class="logoNav">
        <img src="@/assets/img/logo.png" alt="logo" />
      </div>
      <div
        :class="{ showEnlaces: banderaNav }"
        class="enlacesNav"
        id="enlacesNav"
      >
        <nav>
          <ul>
            <li><a href="/">Inicio</a></li>
            <li><a href="/tienda">Tienda</a></li>
            <li><a href="#contacto">Contacto</a></li>
            <li><a href="/presentacion">Nosotros</a></li>
          </ul>
        </nav>
      </div>
      <div class="carro">
        <div class="conteoCarrito">
          {{ carrito }}
          <span></span>
        </div>
        <div>
          <a href="/tienda"
            ><img src="@/assets/img/CARRITO.png" alt="logo"
          /></a>
        </div>
      </div>
    </div>
    <div class="tituloBtn">
      <h1>
        Insúmos <br />
        Médicos
      </h1>
      <p class="mt-4">
        Descubre la más amplia variedad en equipos e insumos de salud
      </p>
      <div>
        <a class="btn btn-warning" href="/tienda">Tienda</a>
        <a class="btn btn-warning" href="#contacto">Contacto</a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const carrito = computed(() => {
      store.getters.GetCarritos
      return store.getters.GetCantidad
    })
    const scrollNav = ref()
    const banderaNav = ref(false)
    const scroll = () => {
      if (window.scrollY > 500) {
        scrollNav?.value?.classList?.add?.('scrolearNav')
      } else if (window.scrollY < 500) {
        scrollNav?.value?.classList?.remove?.('scrolearNav')
      }
    }
    const botonHamburguesa = () => {
      if (banderaNav.value) {
        banderaNav.value = false
      } else {
        banderaNav.value = true
      }
    }
    onMounted(() => {
      window.addEventListener('scroll', scroll)
    })
    return { scrollNav, botonHamburguesa, banderaNav, carrito }
  }
})
</script>
