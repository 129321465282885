/* eslint-disable */
import axios from 'axios'
export default {
  guardarSolicitudCompra(data) {
    return axios.post(
      'https://namiled.herokuapp.com/productos/solicitudcompra/',
      data
    )
  },
  PeticionFlow(data) {
    return axios.post('https://namiled.herokuapp.com/productos/solicitudcompra/', data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
  }
}
