<template>
  <div class="loginPadre">
    <img src="@/assets/img/logo.png" alt="" />
    <p>Insumos Medicos</p>
    <div class="panelLogin">
      <label for="usuario">Usuario</label>
      <input :class="{ error: usuarioError }" type="text" v-model="usuario" />
      <label for="contraseña">Contraseña</label>
      <input
        :class="{ error: passwordError }"
        type="password"
        v-model="password"
      />
      <button @click.prevent="loginUsuario">Ingresar</button>
      <p v-if="usuarioError">Favor de llenar el Usuario</p>
      <p v-if="passwordError">Favor de llenar el Password</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import login from '@/apicall/login'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const error = reactive({ usuarioError: false, passwordError: false })
    const formularioUsuario = reactive({
      usuario: '',
      password: ''
    })
    const router = useRouter()
    const validarFormulario = () => {
      if (
        [formularioUsuario.usuario, formularioUsuario.password].includes('')
      ) {
        if ([formularioUsuario.usuario].includes('')) {
          error.usuarioError = true
        }
        if ([formularioUsuario.password].includes('')) {
          error.passwordError = true
        }
        return false
      }
      error.usuarioError = false
      error.passwordError = false
      return true
    }
    const loginUsuario = async () => {
      try {
        if (validarFormulario()) {
          const response = await login.login({
            email: formularioUsuario.usuario,
            password: formularioUsuario.password
          })
          localStorage.setItem('token', response.data.access_token)
          router.push({
            name: 'panelA'
          })
        }
        console.log('entre')
      } catch (error) {
        console.error(error)
      }
    }
    return {
      ...toRefs(formularioUsuario),
      ...toRefs(error),
      loginUsuario
    }
  }
})
</script>
