import axios from 'axios'
export default {
  postCategorias(id, data) {
    return axios.patch(
      `https://namiled.herokuapp.com/autoadmin/categorias/${id}/`,
      data
    )
  },
  getCategorias() {
    return axios.get('https://namiled.herokuapp.com/autoadmin/categorias/')
  },
  getLogo() {
    return axios.get('https://namiled.herokuapp.com/autoadmin/logoportada/')
  },
  patchLogo(data) {
    return axios.patch(
      `https://namiled.herokuapp.com/autoadmin/logoportada/1/`,
      data
    )
  },
  patchProducto(url, id, data) {
    return axios.patch(`https://namiled.herokuapp.com/${url}/${id}/`, data)
  },
  patchRedesSociales(id, data) {
    return axios.patch(
      `https://namiled.herokuapp.com/autoadmin/links/${id}/`,
      data
    )
  },
  getRedesSociales() {
    return axios.get('https://namiled.herokuapp.com/autoadmin/links/')
  }
}
