<template>
  <div>
    <h4>
      Categoría <span>{{ categoria }}</span>
    </h4>
    <div>
      <p>
        Para cambiar el nombre de la Categoría, <br />
        por favor llenar el campo de abajo con el nombre que solicitas
      </p>
      <input class="form-control" type="text" v-model="Nombre" />
      <button class="btn btn-danger my-2" @click="cambiarNombreCategoria">
        Cambiar
      </button>
    </div>
    <div>
      <div class="productosPorCategoria">
        <div v-for="item in items" :key="item.id">
          <p>{{ item.nombre }}</p>
          <p>{{ item.codigo }}</p>
          <figure>
            <img class="img-size" :src="item.imagen" />
          </figure>
          <br />
          <button
            class="btn btn-danger mx-2"
            @click="eliminarProducto(item.id)"
          >
            Eliminar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalFullscreen"
            @click="editarProducto(item)"
          >
            Editar
          </button>
        </div>
      </div>
      <p>Cambio de productos</p>
      <button
        data-bs-toggle="modal"
        data-bs-target="#exampleModalFullscreen"
        class="btn btn-primary"
        @click="limpiarModal"
      >
        Agregar
      </button>
      <!-- <button>Eliminar</button> -->
      <span>NOTA: Si hace click en eliminar ya no podra recuperarlo</span>
    </div>
    <!-- Modal Editar -->
    <div
      class="modal fade"
      id="exampleModalFullscreen"
      tabindex="-1"
      aria-labelledby="exampleModalFullscreenLabel"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title h4 text-center"
              id="exampleModalFullscreenLabel"
            >
              Editar Producto: {{ editar }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <div class="row">
              <!--  Codigo -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1">Código:</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="itemProducto.codigo"
                />
              </div>
              <!-- Nombre -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1">Nombre:</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="itemProducto.nombre"
                />
              </div>
              <!-- Categoria -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1">Categoria:</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="itemProducto.categoria"
                />
              </div>
              <!-- Descripcion:  -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1">Descripcion: </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="itemProducto.descripcion"
                />
              </div>
              <!-- Imagen -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1"
                  >Imagen:
                  <span v-if="editarProductoFlag" class="required"
                    >*Unico Campo que se modifica aparte</span
                  ></label
                >
                <input
                  @change="carga"
                  class="form-control"
                  type="file"
                  name=""
                  id=""
                />
                <img :src="itemProducto.imagen" alt="" srcset="" />
                <button
                  v-if="editarProductoFlag"
                  class="btn btn-danger"
                  @click="cargarImagen"
                >
                  Modificar
                </button>
              </div>
              <!-- Precio:  -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1">Precio</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="itemProducto.precio"
                />
              </div>
              <!-- Stock -->
              <div class="col-4 card mb-2">
                <label for="exampleFormControlInput1">Stock:</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="itemProducto.stock"
                />
              </div>
              <div class="col-12 mb-2 py-2">
                <button
                  v-if="editarProductoFlag"
                  class="btn btn-primary w-100"
                  @click="EditarProductosBD"
                >
                  Guardar Campos
                </button>
                <button
                  v-else
                  class="btn btn-primary w-100"
                  @click="crearProducto"
                >
                  Crear Producto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loadin -->
    <loading v-if="loadingFlag" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import postCategorias from '@/apicall/autoAdmin'
import Productos from '@/apicall/productos'
import { useStore } from 'vuex'
import loading from '@/components/loading/loading.vue'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'
export default defineComponent({
  props: ['categoria'],
  emits: ['refresh'],
  setup(props, { emit }) {
    const store = useStore()
    const loadingFlag = ref(false)
    const editar = ref()
    const editarProductoFlag = ref(true)
    const imagen = ref({})
    const nombreImagen = ref('')
    const itemProducto = ref({
      categoria: '',
      codigo: '',
      descripcion: '',
      id: '',
      imagen: '',
      nombre: '',
      precio: '',
      stock: ''
    })
    const Nombre = ref()
    const archivo = reactive({
      filename: '',
      fileComplete: '',
      fileBase64: ''
    })
    /* Funcion que permite la llamada positiva de la notificacion */
    const goodNotification = () => {
      Toastify({
        text: 'Se guardo correctamente',
        duration: 3000,
        destination: 'https://github.com/apvarun/toastify-js',
        newWindow: true,
        close: true,
        gravity: 'top', // `top` or `bottom`
        position: 'left', // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: '#00b09b'
        }
      }).showToast()
    }
    /* Funcion que permite la llamada negativa de la notificacion */
    const badNotification = (error) => {
      Toastify({
        text: error,
        duration: 3000,
        destination: 'https://github.com/apvarun/toastify-js',
        newWindow: true,
        close: true,
        gravity: 'top', // `top` or `bottom`
        position: 'left', // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: '#ff0000'
        }
      }).showToast()
    }
    /* Hace un propiedad computada para actualizar la informacion de los productos recibidos de la llamadaApi() y manda el arreglo a la funcion path para renderizar las imagenes de firebase */
    const items = computed(() => {
      return store.state.productos.productos
    })
    /* Realiza Llamada a la api para traer todos los productos de Cuidado Avanzado */
    const llamadaApi = async () => {
      try {
        loadingFlag.value = true
        await store.dispatch(
          'llamadaProductos',
          'productos/productocuidadosavanzados/'
        )
      } catch (error) {
        console.log(error)
      } finally {
        loadingFlag.value = false
      }
    }
    /* Realiza llamada a la base de datos para modificar el nombre de la categoria que se muestra */
    const cambiarNombreCategoria = async () => {
      try {
        loadingFlag.value = true
        await postCategorias.postCategorias(1, {
          nombre: Nombre.value
        })
        emit('refresh')
      } catch (error) {
        console.log(error)
      } finally {
        loadingFlag.value = false
      }
    }
    /* Abre Modal para la edicion del producto y recibe un parametro con el cual
    se identifica el producto que se va a editar */
    const editarProducto = (item) => {
      itemProducto.value = { ...item }
      editarProductoFlag.value = true
      editar.value = itemProducto.value.nombre
      nombreImagen.value = itemProducto.value.imagen
    }
    /* Funcion que realiza la modificacion de los productos en la base de datos */
    const EditarProductosBD = async () => {
      try {
        loadingFlag.value = true
        await Productos.PatchProductos(
          'productos/productocuidadosavanzados/',
          itemProducto.value.id,
          {
            categoria: itemProducto.value.categoria,
            codigo: itemProducto.value.codigo,
            descripcion: itemProducto.value.descripcion,
            nombre: itemProducto.value.nombre,
            precio: itemProducto.value.precio,
            stock: itemProducto.value.stock
          },
          localStorage.getItem('token')
        )
        goodNotification()
        llamadaApi()
      } catch (error) {
        badNotification(error)
        console.log(error)
      } finally {
        loadingFlag.value = false
      }
    }
    const carga = (e) => {
      const files = e.target.files
      if (!files.length) {
        return
      } else {
        const reader = new FileReader()
        reader.onload = function () {
          archivo.fileBase64 = reader.result
        }
        reader.readAsDataURL(files[0])
        archivo.fileComplete = files[0]
        archivo.filename = files[0].name
      }
    }
    const cargarImagen = async () => {
      try {
        loadingFlag.value = true
        await Productos.patchImagenes(
          'productos/productocuidadosavanzados/',
          itemProducto.value.id,
          {
            imagen: archivo.fileBase64
          },
          localStorage.getItem('token')
        )
        goodNotification()
        llamadaApi()
      } catch (error) {
        badNotification(error)
        console.log(error)
      } finally {
        loadingFlag.value = false
      }
    }
    /* Elimina un producto de la base de datos */
    const eliminarProducto = async (id) => {
      try {
        loadingFlag.value = true
        await Productos.deleteProductos(
          'productos/productocuidadosavanzados/',
          id,
          localStorage.getItem('token')
        )
        goodNotification()
        llamadaApi()
      } catch (error) {
        badNotification(error)
        console.log(error)
      } finally {
        loadingFlag.value = false
      }
    }
    const limpiarModal = () => {
      itemProducto.value.categoria = ''
      itemProducto.value.codigo = ''
      itemProducto.value.descripcion = ''
      itemProducto.value.id = ''
      itemProducto.value.imagen = ''
      itemProducto.value.nombre = ''
      itemProducto.value.precio = ''
      itemProducto.value.stock = ''
      archivo.filename = ''
      archivo.fileComplete = ''
      archivo.fileBase64 = ''
      editarProductoFlag.value = false
    }
    const crearProducto = async () => {
      try {
        loadingFlag.value = true
        await Productos.postProductos(
          'productos/productocuidadosavanzados/',
          {
            categoria: itemProducto.value.categoria,
            codigo: itemProducto.value.codigo,
            descripcion: itemProducto.value.descripcion,
            nombre: itemProducto.value.nombre,
            precio: itemProducto.value.precio,
            stock: itemProducto.value.stock,
            imagen: archivo.fileBase64
          },
          localStorage.getItem('token')
        )
        goodNotification()
        llamadaApi()
      } catch (error) {
        badNotification(error)
        console.log(error)
      } finally {
        loadingFlag.value = false
      }
    }
    /* Se inicializa apenas se crea el componente */
    onMounted(() => {
      llamadaApi()
    })
    return {
      cambiarNombreCategoria,
      Nombre,
      items,
      editarProducto,
      editar,
      itemProducto,
      imagen,
      EditarProductosBD,
      loadingFlag,
      carga,
      cargarImagen,
      eliminarProducto,
      crearProducto,
      limpiarModal,
      editarProductoFlag
    }
  },
  components: {
    loading
  }
})
</script>
