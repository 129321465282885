import axios from 'axios'
export default {
  Getproductos(url, token) {
    return axios.get(`https://namiled.herokuapp.com/${url}`, {
      headers: {
        Authorization: `${token}`
      }
    })
  },
  PatchProductos(url, id, data, token) {
    return axios.put(`https://namiled.herokuapp.com/${url}${id}/`, data, {
      headers: {
        Authorization: `${token}`
      }
    })
  },
  patchImagenes(url, id, data, token) {
    return axios.patch(`https://namiled.herokuapp.com/${url}${id}/`, data, {
      headers: {
        Authorization: `${token}`
      }
    })
  },
  deleteProductos(url, id, token) {
    return axios.delete(`https://namiled.herokuapp.com/${url}${id}/`, {
      headers: {
        Authorization: `${token}`
      }
    })
  },
  postProductos(url, data, token) {
    return axios.post(`https://namiled.herokuapp.com/${url}`, data, {
      headers: {
        Authorization: `${token}`
      }
    })
  },
  BuscarProductos(data) {
    return axios.post('https://namiled.herokuapp.com/productos/buscador/', data)
  }
}
