<template>
  <div>
    <footer>
      <div class="footer">
        <p>
          <img src="@/assets/img/logodivincenzo.jpg" alt="" />
          <a href=""> Creado por Divincenzo Marketing Digital</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>
