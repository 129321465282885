<template>
  <div>
    <h4>Productos Destacados</h4>
    <span
      >Aqui van la posibilidad de seleccionar 3 productos destacados que van en
      la pagina de inicio</span
    >
    <div class="productosDestacados"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>
