<template>
  <NavegacionComponents />
  <LinksSociales />

  <div class="contenedorCardInfo">
    <div class="enlacesCategorias">
      <nav class="w-100">
        <h2 ref="categorias">Seleccione una Categoria</h2>
        <ul class="d-flex flex-column flex-md-column justify-content-center">
          <li>
            <a
              class="enlacesAcategorias text-enlace"
              @click="enlaces('avanzado')"
              >{{ categoria3 }}</a
            >
          </li>
          <li>
            <a
              class="enlacesAcategorias text-enlace"
              @click="enlaces('tradicional')"
              >{{ categoria2 }}</a
            >
          </li>
          <li>
            <a
              class="enlacesAcategorias text-enlace"
              @click="enlaces('maquinas')"
              >{{ categoria1 }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="contenedorCard show" id="contenedorCard">
      <div class="input-group mb-3">
        <input
          v-model="buscador"
          type="text"
          class="form-control"
          placeholder="Nombre del producto"
          aria-label="Buscador"
          aria-describedby="button-addon2"
        />
        <button
          class="btn btn-outline-primary"
          type="button"
          id="button-addon2"
          @click.prevent="Buscador"
        >
          Buscar
        </button>
      </div>
      <cuidadosAvanzados v-if="enlace == 'avanzado'" />
      <cuidadosRespiratorios v-if="enlace == 'maquinas'" />
      <cuidadoTradicional v-if="enlace == 'tradicional'" />
      <buscadorProductos
        v-if="enlace == 'buscador'"
        :Productos="ProductosBuscador"
      />
      <div class="navegacionCard mt-2">
        <div class="infoNavegacionCard">
          <p>Amplio Stock</p>
          <span>Despacho rápido y confiable</span>
        </div>
      </div>
    </div>
  </div>

  <div class="seccion3">
    <h2>Chatea con nosotros</h2>
    <p>
      Tienes dudas o quieres <br />
      saber mas acerca de un producto?<br />
      Hablemos por whatsapp
    </p>
    <a class="btnNav2" href="https://wa.link/awfohy">
      <img src="@/assets/img/whatsapps.png" alt="" />Chatea Aquí
    </a>
  </div>

  <div class="seccion4">
    <div class="imagenSeccion4">
      <a href="#contenedorCard"><img src="@/assets/img/3.png" alt="" /></a>
      <a href="#contenedorCard"><img src="@/assets/img/2.png" alt="" /></a>
      <a href="#contenedorCard"><img src="@/assets/img/5.png" alt="" /></a>
    </div>
    <div class="tituloSeccion4"><h2>Productos Destacados</h2></div>
    <div class="descripcionSeccion4">
      <img src="@/assets/img/insumos.png" alt="" />
    </div>
  </div>
  <div class="seccion4b">
    <div class="seccion4bContenido">
      <h3>Contamos con precios Mayoristas</h3>
      <a href="#contacto"
        ><button type="button" class="btn btn-primary">Consultenos</button></a
      >
    </div>
  </div>

  <div class="seccion5Marcas">
    <h3>Trabajamos con las Mejores Marcas</h3>
    <div class="marcas">
      <img src="@/assets/img/marcas.png" alt="" />
    </div>
  </div>

  <div class="seccion6">
    <div class="contenedorOpiniones">
      <img src="@/assets/img/clientes.png" alt="" />
    </div>
    <div class="contenedorFormulario">
      <div class="formulario">
        <div class="form">
          <div>
            <p>Contáctanos</p>
          </div>
          <form id="contacto" class="formularioInput" action="">
            <label for="Nombre">Nombre</label>
            <input v-model="Nombre" type="text" name="Nombre" id="Nombre" />
            <label for="Email">E-mail</label>
            <input v-model="Email" type="email" name="Email" id="Email" />
            <label for="Telefono">Teléfono</label>
            <input
              v-model="Telefono"
              type="number"
              name="Telefono"
              id="Telefono"
            />
            <label for="Mensaje">Mensaje</label>
            <input v-model="Mensaje" type="text" name="Mensaje" id="Mensaje" />
          </form>
        </div>
        <div class="btnForm" id="btnEnviar">
          <p v-if="error" class="fs-6 bg-danger text-white">
            Todos los campos son obligatorios
          </p>
          <p v-if="success" class="fs-6 bg-primary text-white">
            Se envío con exito el correo
          </p>
          <button class="btn btn-primary" @click.prevent="enviarCorreo">
            Enviar
          </button>
        </div>
      </div>

      <div class="datos">
        <p>
          Santiago de Chile - Chile<br />contacto@nalimed.cl <br /><a
            href="https://wa.link/awfohy"
            >+56982119833</a
          >
        </p>
      </div>
    </div>
  </div>
  <!--   FLECHA INDEX -->
  <div class="flechaIndex">
    <a href="#contenidoNavegacion"
      ><img src="@/assets/img/flecha.png" alt=""
    /></a>
  </div>
  <loadingVue v-if="loadingFlag" />
  <FooterComponents />
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue'
import NavegacionComponents from '@/components/navegacion/navegacionComponents.vue'
import loadingVue from '@/components/loading/loading.vue'
import LinksSociales from '@/components/LinksSociales/linksSociales.vue'
import cuidadosAvanzados from '@/components/panels/cuidadosAvanzados.vue'
import cuidadosRespiratorios from '@/components/panels/cuidadosRespiratorios.vue'
import cuidadoTradicional from '@/components/panels/cuidadoTradicional.vue'
import buscadorProductos from '@/components/panels/buscadorProductos.vue'
import FooterComponents from '@/components/footer/footerComponents.vue'
import AutoAdmin from '@/apicall/autoAdmin'
import correo from '@/apicall/correo'
import productos from '@/apicall/productos'
export default defineComponent({
  setup() {
    const enlace = ref('termometro')
    const loadingFlag = ref(false)
    const error = ref(false)
    const success = ref(false)
    const buscador = ref('')
    const ProductosBuscador = ref([])
    const categorias = reactive({
      categoria1: '',
      categoria2: '',
      categoria3: ''
    })
    const enlaces = (Nombre) => {
      enlace.value = Nombre
    }
    const formulario = reactive({
      Nombre: '',
      Email: '',
      Telefono: '',
      Mensaje: ''
    })
    const obtenerValoresSubPanels = async () => {
      try {
        loadingFlag.value = true
        const response = await AutoAdmin.getCategorias()
        categorias.categoria1 = response.data[0].nombre
        categorias.categoria2 = response.data[1].nombre
        categorias.categoria3 = response.data[2].nombre
      } catch (error) {
        console.error(error)
      } finally {
        loadingFlag.value = false
      }
    }
    const enviarCorreo = async () => {
      try {
        if (
          [
            formulario.Nombre,
            formulario.Email,
            formulario.Telefono,
            formulario.Mensaje
          ].includes('')
        ) {
          console.log('Todos los campos son obligatorios')
          error.value = true
          return
        }
        await correo.enviarCorreo({
          Nombre: formulario.Nombre,
          Email: formulario.Email,
          Telefono: formulario.Telefono,
          Mensaje: formulario.Mensaje
        })
        success.value = true
      } catch (error) {
        console.error(error)
      }
    }
    const Buscador = async () => {
      try {
        loadingFlag.value = true
        const obj = {
          busqueda: buscador.value
        }
        const response = await productos.BuscarProductos(obj)
        const arreglo = [
          ...response.data.modelosAvanzados,
          ...response.data.modelosRespiratorios,
          ...response.data.modelosTradicionales
        ]
        ProductosBuscador.value = arreglo
        buscador.value = ''
        enlace.value = 'buscador'
      } catch (error) {
        console.error(error)
      } finally {
        loadingFlag.value = false
      }
    }
    onMounted(() => {
      obtenerValoresSubPanels()
    })
    return {
      enlaces,
      enlace,
      ...toRefs(categorias),
      ...toRefs(formulario),
      enviarCorreo,
      loadingFlag,
      error,
      success,
      buscador,
      Buscador,
      ProductosBuscador
    }
  },

  components: {
    NavegacionComponents,
    cuidadosAvanzados,
    cuidadosRespiratorios,
    cuidadoTradicional,
    FooterComponents,
    LinksSociales,
    loadingVue,
    buscadorProductos
  }
})
</script>
