export default {
  state: {
    carrito: {},
    error: null
  },
  getters: {
    GetCarritos: (state) => {
      state.carrito =
        JSON.parse(localStorage.getItem('carrito')) !== null
          ? JSON.parse(localStorage.getItem('carrito'))
          : {}
      return state.carrito
    },
    GetCantidad: (state) => {
      return Object.values(state.carrito).reduce(
        (acc, { cantidad }) => acc + cantidad,
        0
      )
    },
    GetTotal: (state) => {
      return Object.values(state.carrito).reduce(
        (acc, { cantidad, precio }) => acc + cantidad * precio,
        0
      )
    },
    GetError: (state) => state.error
  },
  mutations: {
    EditCarritos: (state, carrito) => {
      state.carrito[carrito.codigo] = carrito
      localStorage.setItem('carrito', JSON.stringify(state.carrito))
    },
    EditError: (state, error) => {
      state.error = error
    },
    aumentar: (state, id) => {
      state.carrito[id].cantidad++
      localStorage.setItem('carrito', JSON.stringify(state.carrito))
    },
    disminuir: (state, id) => {
      state.carrito[id].cantidad--
      if (state.carrito[id].cantidad == 0) {
        delete state.carrito[id]
      }
      localStorage.setItem('carrito', JSON.stringify(state.carrito))
    },
    eliminar: (state, id) => {
      delete state.carrito[id]
      localStorage.setItem('carrito', JSON.stringify(state.carrito))
    }
  },
  actions: {
    async modificarCarrito({ commit, state }, carrito) {
      try {
        /* eslint-disable */
        state.carrito.hasOwnProperty(carrito.codigo)
          ? (carrito.cantidad = state.carrito[carrito.codigo].cantidad += 1)
          : (carrito.cantidad = 1)
        commit('EditCarritos', carrito)
      } catch (error) {
        commit('EditError', error)
      }
    }
  }
}
