<template>
  <div class="panelLogo">
    <div class="tituloPanelLogo"><h4>Panel Logo</h4></div>
    <div class="logoPanelActual">
      <p>Logo Actual</p>
      <div>
        <img :src="imagenLogo" alt="Logo actual" />
      </div>
      <div class="btnPanelLogo">
        <button class="btn btn-danger" @click="borrarArchivo">Eliminar</button
        ><br />
      </div>
    </div>
    <div class="logoPanelNuevo">
      <p>Agregar nuevo logo</p>
      <input @change="carga" type="file" />
      <button @click="cargarLogo" class="btn btn-danger">Modificar</button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import autoAdmin from '@/apicall/autoAdmin'
import {
  urlLogo,
  storage,
  getDownloadURL,
  referenciaFirebase,
  deleteObject,
  uploadBytes
} from '@/firebase/firebase'
export default defineComponent({
  setup() {
    const imagenLogo = ref('')
    const nombreLogo = ref('')
    const archivo = reactive({
      filename: '',
      fileComplete: ''
    })
    const path = () => {
      try {
        const storageRef = referenciaFirebase(
          storage,
          `${urlLogo}${nombreLogo.value}`
        )
        getDownloadURL(storageRef)
          .then((url) => {
            imagenLogo.value = url
          })
          .catch(() => {
            imagenLogo.value = require('@/assets/img/No_image_available.png')
          })
      } catch (error) {
        imagenLogo.value = require('@/assets/img/No_image_available.png')
      }
    }
    const getLogo = async () => {
      const response = await autoAdmin.getLogo()
      nombreLogo.value = response.data[0].url
      path()
    }
    const borrarArchivo = () => {
      const storageRef = referenciaFirebase(
        storage,
        urlLogo + '/' + nombreLogo.value
      )
      // Delete the file
      deleteObject(storageRef)
        .then(() => {
          autoAdmin.patchLogo({ url: '' }).then(() => {
            getLogo()
          })
        })
        .catch((error) => {
          alert(error)
        })
    }
    const carga = (e) => {
      const files = e.target.files
      if (!files.length) {
        return
      } else {
        const reader = new FileReader()
        reader.onload = function () {
          imagenLogo.value = reader.result
        }
        reader.readAsDataURL(files[0])
        archivo.fileComplete = files[0]
        archivo.filename = files[0].name
      }
    }
    const cargarLogo = async () => {
      if (!nombreLogo.value == '') {
        borrarArchivo()
      }
      continuarCarga()
    }
    const continuarCarga = () => {
      const storageRef = referenciaFirebase(
        storage,
        `${urlLogo}${archivo.filename}`
      )
      console.log(archivo)
      console.log(`${urlLogo}${archivo.filename}`)
      uploadBytes(storageRef, archivo.fileComplete).then(() => {
        autoAdmin.patchLogo({ url: archivo.filename }).then(() => {
          getLogo()
        })
      })
    }
    onMounted(() => {
      getLogo()
    })
    return {
      imagenLogo,
      borrarArchivo,
      carga,
      cargarLogo
    }
  }
})
</script>
