<template>
  <div class="linksRedes">
    <a href=""><img src="@/assets/img/facelogo.png" alt="face" /></a>
    <a href="https://www.instagram.com/nalimed_insumosmedicos/"
      ><img src="@/assets/img/instalogo.png" alt="insta"
    /></a>
    <a href="https://wa.link/0cut6x"
      ><img src="@/assets/img/whatsapps.png" alt="whastapps"
    /></a>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>
