<template>
  <NavegacionComponents />
  <div
    class="tienda d-flex flex-column align-items-center justify-content-center"
  >
    <div class="tituloTienda d-flex">
      <h2>Usted agrego estos items al Carrito</h2>
      <div>
        <a class="btn btn-primary text-white" href="/">Seguir Comprando</a>
      </div>
    </div>
    <div class="tiendaItems d-flex flex-column justify-content-center">
      <div class="carro-contenido row">
        <table id="lista-carrito" class="col-12">
          <thead>
            <tr>
              <th>Codigo Producto / Nombre</th>
              <th>Precio</th>
              <th>Cantidad</th>
              <th>Eliminar item</th>
            </tr>
          </thead>
          <tbody class="carro-items" id="carro-items">
            <tr v-for="item in valorLocalStorage" :key="item.codigo">
              <td>{{ item.codigo }} / {{ item.nombre }}</td>
              <td>$ {{ new Intl.NumberFormat('en').format(item.precio) }}</td>
              <td class="acciones">
                <button
                  class="btn"
                  id="menor"
                  @click="disminuirItem(item.codigo)"
                >
                  -</button
                ><span class="cantidad"> {{ item.cantidad }} </span
                ><button
                  class="btn"
                  id="mayor"
                  @click="aumentarItem(item.codigo)"
                >
                  +
                </button>
              </td>
              <td>
                <button
                  class="btn btn-danger mx-2"
                  @click="EliminarItem(item.codigo)"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-12 col-md-12">
          <div class="d-flex flex-column flex-md-row justify-content-end">
            <div class="valoresColumna text-start">
              <span>Subtotal: </span
              ><span id="sub-total"
                >$ {{ new Intl.NumberFormat('en').format(total) }}</span
              >
            </div>
            <div class="valoresColumna text-start">
              <span>Iva: </span
              ><span id="iva"
                >$
                {{
                  new Intl.NumberFormat('en').format((total * 19) / 100)
                }}</span
              >
            </div>
            <div class="valoresColumna">
              <span>Envío: </span
              ><span id="region"
                >$ {{ new Intl.NumberFormat('en').format(costoEnvio) }}</span
              >
            </div>
            <div class="valoresColumna">
              <span>Total: </span
              ><span id="total"
                >$
                {{
                  new Intl.NumberFormat('en').format(
                    total + (total * 19) / 100 + costoEnvio
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card card-flush py-4 row justify-content-center">
        <div class="col-12 col-md-6">
          <!--begin::Card header-->
          <div class="card-header">
            <div class="card-title">
              <h2>Detalles de la compra</h2>
            </div>
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0">
            <!--begin::Billing address-->
            <div class="d-flex flex-column gap-5 gap-md-7">
              <!--begin::Title-->
              <div class="fs-3 fw-bolder mb-n2">Ticket de compra</div>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="fv-row flex-row-fluid">
                <!--begin::Label-->
                <label class="required form-label">Nombre</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  class="form-control"
                  name="billing_order_address_1"
                  placeholder="Nombre"
                  v-model="nombre"
                />
                <div class="d-flex flex-column flex-md-row gap-5">
                  <!--end::Input-->
                </div>
                <div class="flex-row-fluid">
                  <!--begin::Label-->
                  <label class="required form-label">Apellido</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input
                    class="form-control"
                    name="billing_order_address_2"
                    placeholder="Apellido"
                    v-model="apellido"
                  />
                  <!--end::Input-->
                </div>
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row flex-row-fluid">
                <!--begin::Label-->
                <label class="required form-label">Correo Electronico</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  class="form-control"
                  name="billing_order_city"
                  placeholder="Correo Electronico"
                  v-model="correo"
                />
                <!--end::Input-->
              </div>
              <div class="fv-row flex-row-fluid">
                <!--begin::Label-->
                <label class="required form-label">Dirección</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  class="form-control"
                  name="billing_order_postcode"
                  placeholder="Dirección"
                  v-model="direccion"
                />
                <!--end::Input-->
              </div>
              <div class="fv-row flex-row-fluid">
                <!--begin::Label-->
                <label class="required form-label">Teléfono</label>
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  class="form-control"
                  name="billing_order_state"
                  placeholder="Teléfono"
                  v-model="telefono"
                />
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row">
                <!--begin::Label-->
                <label class="required form-label">Región</label>
                <!--end::Label-->
                <!--begin::Select2-->
                <div class="form-floating border rounded">
                  <select
                    class="form-select"
                    data-placeholder="Select an option"
                    id="kt_ecommerce_edit_order_billing_country"
                    name="billing_order_country"
                    v-model="region"
                    @change="montoRegion"
                  >
                    <option value="" selected disabled>
                      Seleccione una region
                    </option>
                    <option value="región IV A IX">región IV A IX</option>
                    <option value="RESTO REGIONES">RESTO REGIONES</option>
                  </select>
                  <label for="kt_ecommerce_edit_order_billing_country"
                    >Seleccionar Región</label
                  >
                </div>
                <!--end::Select2-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Billing address-->
            <div class="mt-2">
              <a class="btn btn-primary" @click="realizarCompra"
                >Realizar compra</a
              >
            </div>
            <div class="mt-2">
              <p v-if="error" class="p-3 mb-2 bg-danger text-white">
                Llenar todos los campos
              </p>
            </div>
          </div>
          <!--end::Card body-->
        </div>
      </div>
    </div>
  </div>
  <FooterComponents />
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs
} from 'vue'
import NavegacionComponents from '@/components/navegacion/navegacionComponents.vue'
import FooterComponents from '@/components/footer/footerComponents.vue'
import { useStore } from 'vuex'
import tienda from '@/apicall/tienda.js'
export default defineComponent({
  setup() {
    const valores = reactive({
      ticket: '',
      region: '',
      costoEnvio: 0,
      nombre: '',
      apellido: '',
      correo: '',
      direccion: '',
      telefono: ''
    })
    const error = ref(false)
    const store = useStore()
    const valorLocalStorage = ref()
    const refreshDatos = () => {
      valorLocalStorage.value = store.getters.GetCarritos
    }
    const total = computed(() => {
      return store.getters.GetTotal
    })
    const aumentarItem = (id) => {
      store.commit('aumentar', id)
      refreshDatos()
    }
    const disminuirItem = (id) => {
      store.commit('disminuir', id)
      refreshDatos()
    }
    const EliminarItem = (id) => {
      store.commit('eliminar', id)
      refreshDatos()
    }
    const montoRegion = () => {
      if (valores.region === 'región IV A IX') {
        valores.costoEnvio = 5990
      } else {
        valores.costoEnvio = 9900
      }
    }
    const realizarCompra = async () => {
      if (valores.costoEnvio == 0) {
        error.value = true
        return
      }
      const { nombre, apellido, correo, direccion, telefono } = valores
      if ([nombre, apellido, correo, direccion, telefono].includes('')) {
        error.value = true
        return
      }
      error.value = false
      const uuid = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)
      const solicitudFlow = {
        amount: String(
          Math.ceil(total.value + (total.value * 16) / 100 + valores.costoEnvio)
        ),
        email: String(correo),
        commerceOrder: String(uuid),
        urlConfirmation: 'https://nalimed.cl/',
        urlReturn: 'https://nalimed.cl/',
        nombre: nombre,
        apellido: apellido,
        direccion: direccion,
        telefono: telefono,
        region: valores.region,
        carrito: JSON.stringify(valorLocalStorage.value),
        subtotal: total.value,
        iva: (total.value * 19) / 100,
        envio: valores.costoEnvio
      }
      const response = await tienda.PeticionFlow(solicitudFlow)
      const decode = JSON.parse(response.data[0])
      const url = `${decode.url}?token=${decode.token}`
      window.location.href = url
    }
    onMounted(() => {
      refreshDatos()
    })
    return {
      valorLocalStorage,
      total,
      aumentarItem,
      disminuirItem,
      EliminarItem,
      ...toRefs(valores),
      montoRegion,
      realizarCompra,
      error
    }
  },
  components: {
    NavegacionComponents,
    FooterComponents
  }
})
</script>
