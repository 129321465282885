<template>
  <div
    class="position-absolute top-0 start-0 bottom-0 end-0 z-index-1090 text-center d-flex align-items-center justify-content-center"
  >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'loadingComponent'
})
</script>

<style lang="scss" scoped></style>
