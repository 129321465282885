<template>
  <div
    class="row row-cols-12 align-items-center justify-content-center justify-content-md-around"
  >
    <div
      class="col-12 col-md-6 row justify-content-center"
      v-for="item in Productos"
      :key="item.id"
    >
      <div class="card mh-439">
        <div class="itemCard">
          <figure>
            <img class="img-size" :src="item.imagen" />
          </figure>
          <span class="fs-6">{{ item.nombre }}</span>
          <div class="lineaNegra"></div>
          <span class="fs-6">$ {{ parseInt(item.precio).toFixed(0) }}</span>
          <span class="fs-6">Stock: {{ item.stock }}</span>
          <span class="fs-6">Codigo: {{ item.codigo }}</span>
        </div>
        <div class="d-flex align-items-end mt-2">
          <button
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            class="btn btn-primary"
            @click="detalles(item)"
          >
            Detalle
          </button>
          <button class="btn btn-warning mx-2" @click="agregarCarrito(item)">
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Detalles de Producto <br />
            {{ detallesProducto?.nombre }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <img :src="detallesProducto?.imagen" class="img-size" />
            </div>
            <div class="col-6">
              <p>{{ detallesProducto?.descripcion }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    Productos: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const detallesProducto = ref()
    const agregar = ref([])
    const store = useStore()
    const agregarCarrito = async (item) => {
      await store.dispatch('modificarCarrito', item)
    }
    const items = computed(() => {
      return store.state.productos.productos
    })
    const detalles = (item) => {
      detallesProducto.value = item
    }
    return {
      items,
      agregarCarrito,
      agregar,
      detallesProducto,
      detalles
    }
  }
})
</script>
