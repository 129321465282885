<template>
  <div class="linksPanel">
    <h4>Cambio de dirección de las redes sociales</h4>
    <div class="linksRedesPanel">
      <div>
        <p>Facebook</p>
        <input v-model="url['0']" class="form-control" type="text" />
        <button class="btn btn-warning my-2" @click="modificarUrl('0')">
          cambiar
        </button>
      </div>
      <div>
        <p>Instagram</p>
        <input v-model="url['1']" class="form-control" type="text" />
        <button class="btn btn-warning my-2" @click="modificarUrl('1')">
          cambiar
        </button>
      </div>
      <div>
        <p>Whatsapps</p>
        <input v-model="url['2']" class="form-control" type="text" />
        <button class="btn btn-warning my-2" @click="modificarUrl('2')">
          cambiar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import todoRedes from '@/apicall/autoAdmin'
export default defineComponent({
  setup() {
    const url = ref({
      0: '',
      1: '',
      2: ''
    })
    const modificarUrl = async (id) => {
      try {
        const obj = {
          url: url[id]
        }
        await todoRedes.patchRedesSociales(id, obj)
      } catch (error) {
        console.error(error)
      }
    }
    const getRedesSociales = async () => {
      try {
        const redes = await todoRedes.getRedesSociales()
        url.value['0'] = redes.data[0].url
        url.value['1'] = redes.data[1].url
        url.value['2'] = redes.data[2].url
      } catch (error) {
        console.error(error)
      }
    }
    onMounted(() => {
      getRedesSociales()
    })
    return {
      modificarUrl,
      url
    }
  }
})
</script>
